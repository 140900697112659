import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState';
import CmsBlockState from '../../types/CmsBlockState'
import { createLoadingBlockQuery, createSingleBlockQuery } from '@vue-storefront/core/modules/cms/helpers'

const actions: ActionTree<CmsBlockState, RootState> = {
  async list ({ getters, commit }, { filterValues = null, filterField = 'identifier', size = 150, start = 0, excludeFields = null, includeFields = null, skipCache = false }) {
    if (skipCache || !getters.hasItems) {
      const blockResponse = await quickSearchByQuery({
        query: createLoadingBlockQuery({ filterField, filterValues }),
        entityType: 'cms_block',
        size,
        start,
        excludeFields,
        includeFields
      })

      commit(types.CMS_BLOCK_UPDATE_CMS_BLOCKS, blockResponse.items)

      if (!(blockResponse.items && blockResponse.items.length)) return

      let validBaners = {
        'mainbanner': [],
        // 'secondbanner': [],
        // 'thirdbanner': [],
        // 'categorypl': [],
        'customer_reviews': null,
        'infoBarHeader': null,
        'mainpage_press': null,
        'size_guide': null,
        'megamenu_ubrania': null,
        'megamenu_okazje': null,
        'megamenu_projektanci': null,
        'megamenu_abonament': null,
        'megamenu_pakiety': null,
        'megamenu_marketplace': null,
        'instagram_followers': null,
        'mainpage_category_1': null,
        'mainpage_category_2': null,
        'mainpage_category_3': null,
        'mainpage_category_4': null,
        'specialOfferBar': null
      }
      const isBanerValid = (banerInfo) => {
        if (!(banerInfo.urlDesktop && banerInfo.urlMobile && banerInfo.from && banerInfo.to && banerInfo.link)) return false
        const now = new Date()
        let pattern = /(\d{2})\.(\d{2})\.(\d{4})/
        let fromDate = new Date(banerInfo.from.replace(pattern, '$3-$2-$1').replace(/-/g, '/'))
        let toDate = new Date(banerInfo.to.replace(pattern, '$3-$2-$1').replace(/-/g, '/'))
        return fromDate < now && toDate > now
      }
      const parseBaner = (baner) => {
        return JSON.parse(baner.content.replace(/<\/?p>/g, '').replace('&amp;', '&'))
      }

      for (let baner of blockResponse.items) {
        for (let type of Object.keys(validBaners)) {
          if (!baner.identifier.includes(type)) continue
          let parsedBaner
          try {
            if (['customer_reviews', 'size_guide'].includes(baner.identifier)) {
              parsedBaner = baner
            } else {
              parsedBaner = parseBaner(baner)
            }
          } catch {
            continue
          }
          if (type !== 'mainpage_press') parsedBaner['id'] = baner.id
          if (['mainbanner'].includes(type) && !isBanerValid(parsedBaner)) continue

          ['mainbanner'].includes(type)
            ? validBaners[type].push(parsedBaner)
            : validBaners[type] = parsedBaner
        }
      }
      for (let type of ['mainbanner']) {
        validBaners[type] = validBaners[type].sort((a, b) => {
          let left = a.order ? Number(a.order) : 0
          let right = a.order ? Number(b.order) : 0
          if (left < right) { return -1 }
          if (left > right) { return 1 }
          return 0
        })
      }
      commit(types.CMS_BLOCK_UPDATE_BANERS, validBaners)

      return blockResponse.items
    }

    return getters.getCmsBlocks
  },
  async single ({ getters, commit }, { key = 'identifier', value, excludeFields = null, includeFields = null, skipCache = false }) {
    let cmsBlock = getters.findCmsBlocks({ key, value })

    if (skipCache || cmsBlock.length === 0) {
      const blockResponse = await quickSearchByQuery({
        query: createSingleBlockQuery({ key, value }),
        entityType: 'cms_block',
        excludeFields,
        includeFields
      })

      if (blockResponse.items.length > 0) {
        const items = blockResponse.items.filter(item => item[key] === value)
        commit(types.CMS_BLOCK_ADD_CMS_BLOCK, items[0])
        return items[0]
      }
    }

    return cmsBlock[0]
  },
  addItem ({ commit }, block) {
    commit(types.CMS_BLOCK_ADD_CMS_BLOCK, block)
  }
}

export default actions
